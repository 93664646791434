// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const my = {
  // login
  login: `ဝင်ရောက်ရန် `,

  // register
  register: `အကောင့်ဖွင့်ရန် `,

  // home
  game_partners: `ဂိမ်းမိတ်ဖွဲများ`,
  responsible_gaming: `တာဝန်သိဂိမ်းများ`,
  supported_bank: `လက်ခံသည့် ဘဏ်များ`,
  load_more: `ထပ်ရှာရန်`,

  // game
  choose_your_favourite_x_games: `သင့်အကြိုက်ဆုံးကိုရွေးချယ်ပါ {{x}} ဂိမ်း`,
  choose_your_favourite_x: `သင့်အကြိုက်ဆုံးကိုရွေးချယ်ပါ {{x}}`,
  launch: `ကစားရန်`,

  // notification
  notifications: `အသိပေးချက်များ`,
  you_have_x_unread_messages: `သင်မဖတ်သေးတဲ့ {{x}} မက်ဆေ့များရှိသည် `,

  // deposit/withdraw
  drop_or_select_file: `ချန်ထားရန် (သို) ရွေးချယ်ရန်`,
  drop_files_here: `ဖိုင်ကို ဧနေရာတွင်ချရန်`,
  bank: `ဘဏ်`,
  bank_name: `ဘဏ်အမည်`,
  account_name: `အကောင့်အမည်`,
  account_number: `အကောင့်နံပတ်`,
  bank_account: `ဘဏ်အကောင့်`,
  something_went_wrong: `တစုံတရာမှားယွင်းနေသည်. တာဝန်ရှိသူများဆက်သွယ်ရန်`,
  deposit_amount_between_x_and_y: `ရှိသင့်သည့် သွင်းငွေအမောင့် {{x}} နှင့် {{y}} ကြား`,
  minimum_amount_is_x_y: `အနဲဆုံး ငွေပမာဏမှာ {{x}}{{y}}`,
  payment_method: `ငွေပေးချေသည့်ပုံစံ`,
  mode: `ပုံစံ`,
  transfer_to: `ငွေလွဲပေးမည့်သူထံသို့`,
  select: `ရွေးချယ်ရန်`,
  account: `အကောင့်`,
  amount: `ပမာဏ`,
  deposit: `သွင်းငွေပမာဏ`,
  withdraw: `ငွေထုတ်`,
  amount_no_exceed_balance: `ထုတ်ယူသည့်ပမာဏသည် ရရှိနိုင်သောလက်ကျန်ပမာဏထက် မကျော်လွန်သင့်ပါ။`,
  minimum_withdraw_amount_is_x: `အနဲဆုံးထုတ်ယူနိုင်သည့်ပမာဏ {{x}}`,
  available_balance: `ထုတ်နိုင်သည့် ပမာဏ`,
  amount_withdrawn: `ထုတ်သည့် ပမာဏ`,
  no_bank_account: `သင့််မှာ ထုတ်ယူနိုင်သည့်ပမာဏများမလုံလောက်ပါ ,ထပ်ဖြည့်ရန်`,
  add_account: `အကောင့်ထည့်ရန်`,
  account_name_must_be_4: `အကောင့်အမည်သည် အရှည် 4 ထက်ကြီးရပါမည်။`,
  please_select_bank: `ဘဏ့်အကောင့်ရွေးချယ်ရန်`,
  account_number_must_be_x: `အကောင့် နံပတ်သည် 10-14 ထိရှည်ရမည်`,
  minimum_length_is_x: `အနိမ့်ဆုံးအရှည်မှာ {{x}}`,
  maximum_length_is_x: `အများဆုံးအရှည်မှာ {{x}}`,
  withdraw_information: `ငွေထုတ်အချက်လက်များ`,
  account_holder_name: `အကောင့်ပိုင်ရှင်`,
  name_of_the_account_holder: `အကောင့်ကိုင်ဆောင်သူအမည်`,
  fill_in_bank_account: `ဘဏ်အကောင့်ထည့်သွင်းရန်`,
  please_upload_receipt: `ကျေးဇူးပြုပြီး ပြေစာတင်ရန်`,
  deposit_bonus: `ငွေသွင်းဘောနပ်စ်`,
  bank_transfer: `ဘဏ်မှငွေလွဲရန်`,
  upload_receipt: `ပြေစာတင်ရန်`,
  select_bonus: `ဘောနပ်စ်ဘောနပ်စ် ရွေးချယ်ရန်`,
  no_promotion: `ပရိုမိုးရှင်းရွေးချယ်ရန်`,

  // transaction history
  transaction_history: `မှတ်တမ်း`,

  // bet history
  bet_history: `လောင်ကြေးမှတ်တမ်း`,
  game_provider: `ဂိမ်းအားပံ့ပိုးပေးသူ`,
  bet_count: `လောင်းကြေးတွက်ချက်`,
  bet_amount: `လောင်းကြေး ပမာဏ`,
  win_loss: `နိုင်/ရှုံး`,
  rebate: `ပြန်အမ်းငွေ`,
  rebates: `ပြန်အမ်းငွေ`,
  turnover: `ထိုးကြေး`,
  game_category: `ဂိမ်းအမျိုးစားများ`,

  // all
  all: `အားလုံး`,
  x_is_required: `{{x}} လိုအပ်`,
  note: `မှတ်ချက်`,
  save: `သိမ်းထား`,
  please_select_x: `ရွေးချယ်ရန်{{x}}`,
  title: `ခေါင်းစဉ်`,
  date: `ရက်စွဲ`,
  type: `ပုံစံ`,
  status: `အခြေနေ`,
  remark: `မှတ်ချက်`,
  clear: `ရှင်းလင်းပျက်သားသည်`,
  date_from: `နေ့စွဲမှ`,
  date_to: `နေ့စွဲသို့ `,
  category: `အမျိုးစား`,
  search: `ရှာဖွေရန်`,
  new: `သတင်း`,
  change_password: `သင့် လျို့ဝှက်နံပါတ်အားပြောင်းရန်`,
  logout: `ထွက်ရန်`,
  username: `အသုံးပြုသူအမည်`,
  password: `စကားဝှက်`,
  forgot_password: `စကားဝှက်မေ့နေပါသလား`,
  confirm: `အတည်ပြုပါ။`,
  dont_have_account: `အသုံးပြုသူအကောင့် မရှိဘူးလား။`,
  contact_us: `ကြှနျုပျတို့ကိုဆကျသှယျရနျ`,
  game_menu: `ဂိမ်းမီနူး`,
  bonus: 'ဘုန်း',
  no_data: 'အချက်အလက်မရှိပါ',
  terms_and_condition: 'စစည်းမျဉ်းစည်းကမ်း နှင့် လိုက်နာရန်အချက်အလက်များ',
  game_list: 'ဂိမ်းစာရင်း',
  play: 'ကစားရန်',
  games: 'ဂိမ်များ',
  casino: 'ကာစီနို',
  slots: 'စလော့',
  card: 'ကဒ်',
  fishing: 'အင်အားရှယ်ပြီး',
  sport: 'အားကစား',
  sports: 'အားကစား',
  bonuses: 'ဘောနပ်စ်',
  account_profile: `အကောင့်ပရိုဖိုင်`,
  email: `အီးမေးလ်`,
  full_name: `နာမည်အပြည့်အစုံ`,
  phone_number: `ဖုန်းနံပါတ်`,
  level: `အဆင့်`,
  bank_list: `ဘဏ်စာရင်း`,
  in_maintenance: `ထိန်းသိမ်းခြင်း။`,
  birthday: `မွေးနေ့`,
  birthday_bonus: `အပိုဆုတစ်ခုအတွက် သင့်မွေးနေ့ကို ထည့်ပါ။ 🎉🎁`,
  referral_code: `ရည်ညွှန်းကုဒ်`,
  invalid_x: `Invalid {{x}}`,
  extra: `Extra`,
  referral: `ရည်ညွှန်းသည်။`,
  my_referral: `ကျွန်ုပ်၏ ရည်ညွှန်းချက်`,
  my_claim: `ကျွန်ုပ်၏ တောင်းဆိုချက်`,
  claim_profit: `အမြတ်ငွေကို တောင်းဆိုပါ။`,
  available_profit: `ရရှိနိုင်သောအမြတ်`,
  claimed_profit: `တောင်းဆိုထားသောအမြတ်`,
  all_time_profit: `အချိန်တိုင်းအမြတ်`,
  referral_note_on_x: `သင့်သူငယ်ချင်းများ {{x}} တွင် ကစားသည့်အခါတိုင်း အမြတ်ရယူပါ`,
  tier: `အဆင့်`,
  commission: `ကော်မရှင်`,
  referred_member: `ရည်ညွှန်းအဖွဲ့ဝင်`,
  profit_earned: `အမြတ်များ`,
  account_information: `အကောင့်အချက်အလက်`,
  invite_friends: `သူငယ်ချင်းများကိုဖိတ်ကြားသည်`,
  how_it_work: `ဘယ်လိုအလုပ်လုပ်လဲ?`,
  invite_note: `သင့်သူငယ်ချင်းသည် သင့်ရည်ညွှန်းကုဒ်ကို အသုံးပြု၍ အကောင့်ဖွင့်သည့်အခါ သီးသန့် ညွှန်းဆိုခွင့်အပိုဆုများ ရယူလိုက်ပါ။`,
  share_via: `တဆင့်မျှဝေပါ။`,
  referral_link: `ရည်ညွှန်းလင့်ခ်`,
  total_profit: `စုစုပေါင်းအမြတ်`,
  copied: `ကူးယူသည်။`,
  nothing_to_claim: `ဘာမှ တောင်းဆိုစရာ မရှိပါဘူး။`,
  claim_history: `တိုင်ကြားမှုမှတ်တမ်း`,
  choose_date: `ရက်စွဲကို ရွေးပါ။`,
  select_both_date: 'အသက်မှာနေသည့် နေ့မှာနိုင်သည့်အမျိုးအစာကိုရွေးပါ',
  hello: 'မင်္ဂလာပါ',
  my_downline: `ငါ့ဒေါင်းလိုင်း`,
  downline: `သင့်အားမိတ်ဆက်ပေးသူ`,
  forgot_password_contact: 'သင်၏စကားဝှက်ကိုေျပာရန္အေသေျပဆံုးအမျိုးအစာအားေဆာင့္ေျပာရန္ကိုမွမ္းမိမ္းေျပာပါ',
  submit: 'တင်မည်',
  current_password: 'လက်ရှိစကားဝှက်',
  new_password: 'စကားဝှက်အသစ်',
  confirm_new_password: 'စကားဝှက်အသစ်အတည်ပြုရန်',
  already_have_an_account: 'ယခုစကားဝှက်တစ်ခုရှိပြီးဆု',
  share: 'မျှဝေသည်',
  more_info: 'အခြားချက်လက်များ',
  copy_link: 'လင့်ရယူရန်',
  share_to_friend: 'မျှဝေရန်',
  get_bonus: 'ဘောနပ်စ်ရယူရန်',
  copy_your_referral: 'သင့်ရိုးချပုံအကြောင့်ကိုကြော်ငြင်ဆိုနေသည်နှင့်နိုဝင်မှသားမျှဝေရရှိရန်မွှေချချွအသုံးပြုနိုင်ပါ။',
  last_x_digit_bank_ref_number: `Bank Ref နံပါတ်၏ နောက်ဆုံးဂဏန်း {{x}}`,
  bank_ref_number_validation_x: `Bank Ref Number လိုအပ်ပြီး အက္ခရာအရှည် {{x}} ဖြစ်ရပါမည်။`,
  card_game: 'ဖဲကစားနည်း',
  e_sports: 'အီး ဘောလုံး',
  instant_game: 'အကြော်ငြားဆုံးဂိမ်း',
  sort: 'အမျိုးအစား',
  name: 'နာမည်',
  default: 'ပုံသေ',
  e_sports_bonus: 'အီး အားကစားဘောနပ်စ်',
  fishing_bonus: 'ငါးပစ်ဘောနပ်စ်',
  casino_bonus: 'ကာစီနို ဘောနပ်စ်',
  sports_bonus: 'အားကစားဘောနပ်စ်',
  slots_bonus: 'စလော့ဘောနပ်စ်',
  instant_game_bonus: 'အကြော်ငြားဆုံးဂိမ်း',
  card_game_bonus: 'ကတ်ဂိမ်း အပိုဆု',
  all_bonus: 'ဘောနပ်စ်များ အားလုံး',
  how_to_get_referral_bonus: 'သူငယ်ချင်းမိတ်ဆက်ဘောနပ်စ် ဘယ်လိုရအောင်ယူမလဲ?',
  steps_to_claim: 'ဘောနပ်စ်ရယူပုံအဆင့်ဆင့်',
  share_your_referral_code:
    '၁.သင့်သူငယ်ချင်းကို Referral code ကိုသူငယ်ချင်းအား အကောင့်ဖွင့်နိုင်ရန်အတွက် viber, messenger,telegram.facebook.twitter,or instagram.ကနေတဆင့်မျှဝေပေးရန်',
  after_friend_register:
    '၂.သူငယ်ချင်းက zk99.မှာအကောင့်ဖွင့်ပြီးပြီဆိုရင် မိတ်ဆက်ဘောနပ်စ်ရယူရန်အတွက် နှစ်ဉီးလုံးက ငွေသွင်းကစားရန်လိုအပ်ပါတယ်',
  refer_a_friend_bonus: '၃.သူငယ်ချင်းမိတ်ဆက်ဘောနပ်စ် တွက်ချက်နည်း',
  turnover_played: 'ကစားသည့်ထိုးကြေးစုစုပေါင်းxကော်မရှင်%=သူငယ်ချင်းမိတ်ဆက်ဘောနပ်စ်',
  example: 'ဉပမာ',
  turnover_formula_x: 'ထိုးကြေး (၁,၀၀၀,၀၀၀)x၀.၀၀၂% (ကော်မရှင် %)= ၂၀၀၀ {{x}}',
  note_bonus_only: 'မှတ်ချက်*ဘောနပ်စ်ကို နောက်တနေ့ ၁၂ နာရီ နောက်ပိုင်းသာ ရယူနိုင်သည်',
  note_bonus_only_x: 'မှတ်ချက်*ဘောနပ်စ်ကို နောက်တနေ့ {{x}} နောက်ပိုင်းသာ ရယူနိုင်သည်',
  access_dashboard:
    '၄. အမြတ်ငွေနဲ့ ကော်မရှင်ကိုကြည့်ရှု့ရန် သူငယ်ချင်းမိတ်ဆက် စာမျက်နှာတွင် ဝင်ရောက်ကြည့်ရှု့နိုင်ပါသည်',
  click_this_button_to_claim: 'အမြတ်ငွေတောင်းဆိုပါ ဆိုတဲ့စာသားကိုနှိပ်ပြီး ဘောနပ်စ်ကို ရယူနိုင်ပါသည်',
  commision_tier: 'ကော်မရှင်အဆင့်',
  member_downline: 'ကိုယ့်ရဲ့ မိတ်ဆက်ပေးသူများ',
  bonus_tutorial_video: 'ဘောနပ်စ်ရယူပုံအဆင့်ဆင့် လက်တွေ့ပြပုံ',
  popularity: 'နာမည်တော်',
  no_balance: 'သင့်အတွက် လက်ကျန်ငွေများကိုလက်ခံရန်အသိအမှတ်များ မရှိပါ',
  invalid_captcha: 'မမှန်ကန်သော အတည်ပြုကုဒ်',
  captcha: 'အတည်ပြုကုဒ်',
  claim_bonus: 'တောင်းဆိုမှု',
  claimed: 'တိုင်ကြားထားသည်။',
  bonus_claimed: 'အပိုဆုများကို တောင်းဆိုထားသည်။',
  online_banking: 'အွန်လိုင်းဘဏ်လုပ်ငန်း',
  please_enter_email: 'စကားဝှက်ပြန်လည်သတ်မှတ်ခြင်းလုပ်ငန်းစဉ်ကို ဆက်လက်ဆောင်ရွက်ရန် သင့်အီးမေးလ်လိပ်စာကို ထည့်သွင်းပါ။',
  please_select_a_bank: 'ဘဏ်တစ်ခုကို ရွေးပါ။',
  deposit_from_bank: 'ဘဏ်မှအပ်ငွေ',
  from_selected_promotion: 'ရွေးချယ်ထားသော ပရိုမိုးရှင်းမှ',
  from_selected_bank: 'ရွေးချယ်ထားသောဘဏ်မှ',
  network_error: 'ကွန်ရက် အမှား',
  we_have_sent_otp: 'သင့်ဖုန်းနံပါတ်သို့ OTP ပို့ချမှုပို့ပြီးပြီ',
  your_otp_expire: 'OTP သင့်မှာဆုံးလိုက်ပါသည်',
  didnt_receive: 'မကြာခဲ့ဘူးလား?',
  send_again: 'ထပ်ပို့ပါ',
  resend_again: 'နောက်ထပ်ပို့ပါ',
  invalid_verification: 'မှန်ကန်ဘူးနောက်တွင်အတည်ပြုကုတ်!',
  otp_expired:
    'OTP သည်ဆုံးလိုက်တာကိုကျွန်တော်သွားပြီးပြီ, ကျေးဇူးပါပြီးနောက်တစ်ခုအနေနှင့်ပြန်လည်စမ်းသပ်မည့်နေရာသို့ထပ်ရန်ဖြစ်ပါသည်။',
  otp_expired_redirect: 'OTP သည်ဆုံးလိုက်တာကိုကျွန်တော်သွားပြီးပြီ! ဝင်မည့်အပြင်မှာအသိပေးစွာသို့သွားမည်',
  captcha_check_failed: 'Captcha စစ်ဆေးမှုမအောင်မြင်!',
  verify_phonenumber: 'ဖုန်းနံပါတ်ကိုအတည်ပြုပါ',
  send_otp: 'OTP ပို့ပါ',
  success_verification: 'သင့်ဖုန်းနံပါတ်ကိုအတည်ပြုလုပ်ပြီးပြီ! အောက်ပါအောင်မြင်စွာပြင်ဆင်ပါသည်။',
  dont_show_again: 'နောက်တစ်ခေါက် မပြပါနဲ့',
  next: 'နောက်တစ်ဆင့်',
  back: 'နောက်သို့',
  close: 'ပိတ်မည်',
  payment_draft:
    'သင်သည် ငွေပေးချေပြီးဖြစ်ကြောင်း အတည်ပြုပါက၊ ငွေပေးချေမှုကို ဆက်လက်လုပ်ဆောင်နေနိုင်သောကြောင့် ခဏစောင့်ပါ။',
  go_to_home: 'အိမ်ကိုပြန်',
  payout: `ပေးချေခြင်း။`,
  get: `သွင်း`,
  deposit_fee: `အပ်နှံကြေး`,
  total_amount_received: `စုစုပေါင်းလက်ခံရရှိသည့်ပမာဏ`,
  cancel: `မလုပ်တော့`,
  deposit_amount: `အပ်ငွေပမာဏ`,
  confirm_to_pay: `ပေးချေရန် အတည်ပြုပါ။`,
  deposit_method: `အပ်ငွေနည်းလမ်း`,
  redeem_angpau: `အန်းပေါင်ကို ရွေးပါ။`,
  promo_concluded: `ပရိုမိုးရှင်းပြီးပါပြီ။`,
  can_only_claim: `မလေးရှားစံတော်ချိန်မှ {{x}} မှ {{y}} ကြားတွင်သာ ဘောနပ်စ်ကို တောင်းဆိုနိုင်သည်။`,
  please_login: `ကျေးဇူးပြု၍ ဝင်ပါ။`,
  terms_n_conditions: `စည်းကမ်းသတ်မှတ်ချက်များ`,
  claim_now: `ယခုတောင်းဆိုပါ။`,
  the_activity_x_in: `လုပ်ဆောင်ချက် {{x}} တွင်`,
  starts: `စတင်သည်။`,
  ends: `ကုန်ဆုံးသည်။`,
  list_of_winners: `အနိုင်ရသူများစာရင်း`,
  days: `နေ့ရက်များ`,
  hours: `နာရီ`,
  minutes: `မိနစ်များ`,
  seconds: `စက္ကန့်`,
  please_select_payment_method: 'ငွေပေးချေမှုနည်းလမ်းကို ရွေးချယ်ပါ။',
};

export default my;
