// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  // login
  login: `ĐĂNG NHẬP`,

  // register
  register: `ĐĂNG KÝ`,

  // home
  game_partners: `Đối tác trò chơi`,
  responsible_gaming: `Chơi game có trách nhiệm`,
  supported_bank: `Ngân hàng được hỗ trợ`,
  load_more: `Tải thêm`,

  // game
  choose_your_favourite_x_games: `Chọn trò chơi {{x}} yêu thích của bạn`,
  choose_your_favourite_x: `Chọn yêu thích của bạn {{x}}`,
  launch: `Vào`,

  // notification
  notifications: `Thông báo`,
  you_have_x_unread_messages: `Bạn có {{x}} tin nhắn chưa đọc`,

  // deposit/withdraw
  drop_or_select_file: `Thả hoặc chọn tệp`,
  drop_files_here: `Thả tập tin ở đây`,
  bank: `Ngân hàng`,
  bank_name: `Tên ngân hàng`,
  account_name: `Tên tài khoản`,
  account_number: `Số tài khoản`,
  bank_account: `Tài khoản ngân hàng`,
  something_went_wrong: `Đã xảy ra lỗi. Vui lòng liên hệ với bộ phận hỗ trợ.`,
  deposit_amount_between_x_and_y: `Số tiền gửi phải nằm trong khoảng từ {{x}} đến {{y}}`,
  minimum_amount_is_x_y: `Số tiền tối thiểu là {{x}}{{y}}`,
  payment_method: `Phương thức thanh toán`,
  mode: `Cách thức`,
  transfer_to: `Chuyển tới`,
  select: `Lựa chọn`,
  account: `Tài khoản`,
  amount: `Số lượng`,
  deposit: `Tiền gửi`,
  withdraw: `Rút`,
  amount_no_exceed_balance: `Số tiền rút không được vượt quá số dư khả dụng`,
  minimum_withdraw_amount_is_x: `Số tiền rút tối thiểu là {{x}}`,
  available_balance: `Số dư khả dụng`,
  amount_withdrawn: `Số tiền đã rút`,
  no_bank_account: `Bạn chưa có tài khoản để rút về, Thêm ngay`,
  add_account: `Thêm tài khoản`,
  account_name_must_be_4: `Tên tài khoản phải dài hơn 4`,
  please_select_bank: `Vui lòng chọn một Tài khoản ngân hàng`,
  account_number_must_be_x: `Số tài khoản phải dài 10-14`,
  minimum_length_is_x: `Độ dài tối thiểu là {{x}}`,
  maximum_length_is_x: `Độ dài tối đa là {{x}}`,
  withdraw_information: `Rút thông tin`,
  account_holder_name: `Tên Chủ Tài khoản`,
  name_of_the_account_holder: `Tên chủ tài khoản`,
  fill_in_bank_account: `Điền vào tài khoản ngân hàng`,
  please_upload_receipt: `Vui lòng tải lên biên nhận`,
  deposit_bonus: `Tiền thưởng gửi tiền`,
  bank_transfer: `Chuyển khoản ngân hàng`,
  upload_receipt: `Tải lên biên nhận`,
  select_bonus: `Chọn tiền thưởng`,
  no_promotion: `Không có khuyến mãi`,

  // transaction history
  transaction_history: `Lịch sử giao dịch`,

  // bet history
  bet_history: `lịch sử đặt cược`,
  game_provider: `Nhà cung cấp trò chơi`,
  bet_count: `Số lần đặt cược`,
  bet_amount: `Số tiền đặt cược`,
  win_loss: `Thắng thua`,
  rebate: `xếp hạng`,
  rebates: `HOÀN TRẢ`,
  turnover: `Doanh số`,
  game_category: `Thể loại trò chơi`,

  // all
  all: `Tất cả`,
  x_is_required: `{{x}} là bắt buộc`,
  note: `Ghi chú`,
  save: `lưu`,
  please_select_x: `Vui lòng chọn {{x}}`,
  title: `Tiêu đề`,
  date: `Ngày`,
  type: `Kiểu`,
  status: `Trạng thái`,
  remark: `Nhận xét`,
  clear: `Làm mới`,
  date_from: `Từ ngày`,
  date_to: `Ngày tới`,
  category: `Loại`,
  search: `Tìm kiếm`,
  new: `Mới`,
  change_password: `Đổi mật khẩu`,
  logout: `Đăng xuất`,
  username: `Tên tài khoản`,
  password: `Mật khẩu`,
  forgot_password: `Quên mật khẩu`,
  confirm: `XÁC NHẬN`,
  dont_have_account: `Bạn chưa có tài khoản người dùng?`,
  contact_us: `Liên hệ chúng tôi`,
  game_menu: `Danh sách game`,
  bonus: 'Thưởng',
  no_data: `Không có dữ liệu`,
  terms_and_condition: 'ĐIỀU KHOẢN & ĐIỀU KIỆN',
  game_list: 'DANH SÁCH TRÒ CHƠI',
  play: 'Chơi',
  games: 'Trò chơi',
  casino: 'Sòng bạc',
  slots: 'Máy đánh bạc',
  card: 'Thẻ',
  fishing: 'Đánh bắt cá',
  sport: 'Thể thao',
  sports: 'Các môn thể thao',
  bonuses: 'Tiền thưởng',
  account_profile: `Thông tin tài khoản cá nhân`,
  email: `E-mail`,
  full_name: `Họ và tên`,
  phone_number: `Số điện thoại`,
  level: `Mức độ`,
  bank_list: `Danh sách ngân hàng`,
  in_maintenance: `Đang bảo trì`,
  birthday: `Sinh nhật`,
  birthday_bonus: `Nhập ngày sinh của bạn để nhận tiền thưởng! 🎉🎁`,
  referral_code: `Mã giới thiệu`,
  invalid_x: `Không hợp lệ {{x}}`,
  extra: `Thêm`,
  referral: `Giới thiệu`,
  my_referral: `Lời giới thiệu của tôi`,
  my_claim: `Yêu cầu của tôi`,
  claim_profit: `YÊU CẦU LỢI NHUẬN`,
  available_profit: `Lợi nhuận khả dụng`,
  claimed_profit: `Lợi nhuận được yêu cầu`,
  all_time_profit: `Lợi nhuận mọi thời đại`,
  referral_note_on_x: `Kiếm lợi nhuận mỗi khi bạn bè của bạn chơi trên {{x}}`,
  tier: `Tầng`,
  commission: `Nhiệm vụ`,
  referred_member: `Thành viên được giới thiệu`,
  profit_earned: `Lợi nhuận kiếm được`,
  account_information: `thông tin tài khoản`,
  invite_friends: `Mời bạn bè`,
  how_it_work: `Nó hoạt động như thế nào?`,
  invite_note: `Nhận phần thưởng giới thiệu độc quyền khi bạn bè của bạn đăng ký bằng mã giới thiệu của bạn!`,
  share_via: `Chia sẻ qua`,
  referral_link: `Liên kết giới thiệu`,
  total_profit: `Tổng lợi nhuận`,
  copied: `Đã sao chép`,
  nothing_to_claim: `Không có gì để yêu cầu`,
  claim_history: `Lịch sử yêu cầu`,
  from: `Từ`,
  to: `ĐẾN`,
  choose_date: `Chọn ngày`,
  select_both_date: `Chọn cả Ngày Từ và Đến`,
  hello: 'Xin chào',
  my_downline: `Tuyến dưới của tôi`,
  downline: `Tuyến dưới`,
  forgot_password_contact:
    'Vui lòng liên hệ với bộ phận hỗ trợ khách hàng được cung cấp bên dưới để giúp bạn thay đổi mật khẩu',
  submit: 'Nộp',
  current_password: 'Mật khẩu hiện tại',
  new_password: 'mật khẩu mới',
  confirm_new_password: 'Xác nhận mật khẩu mới',
  already_have_an_account: 'Bạn co săn san để tạo một tai khoản?',
  share: 'Chia sẻ',
  more_info: 'Thêm thông tin',
  copy_link: 'Sao chép đường dẫn',
  share_to_friend: 'Chia sẻ tới bạn bè',
  get_bonus: 'Nhận tiền thưởng',
  copy_your_referral:
    'Sao chép liên kết giới thiệu của bạn và gửi tới phương tiện truyền thông xã hội và kiếm tín dụng từ tuyến dưới của bạn!',
  last_x_digit_bank_ref_number: `{{x}} chữ số cuối cùng của số Tham chiếu Ngân hàng`,
  bank_ref_number_validation_x: `Số tham chiếu ngân hàng là bắt buộc và phải có độ dài {{x}} ký tự`,
  card_game: 'Chơi bài',
  e_sports: 'E-Sports',
  instant_game: 'Trò chơi tức thì',
  sort: 'Loại',
  name: 'Tên',
  default: 'Mặc định',
  e_sports_bonus: 'E-Sports Thưởng',
  fishing_bonus: 'Tiền thưởng câu cá',
  casino_bonus: 'Tiền thưởng sòng bạc',
  sports_bonus: 'Tiền thưởng thể thao',
  slots_bonus: 'Tiền thưởng máy đánh bạc',
  instant_game_bonus: 'Phần thưởng trò chơi tức thì',
  card_game_bonus: 'Tiền thưởng trò chơi bài',
  all_bonus: 'Tất cả tiền thưởng',
  // TODO: translate to other languages
  how_to_get_referral_bonus: 'Làm thế nào để nhận được tiền thưởng giới thiệu bạn bè?',
  steps_to_claim: 'Các bước để nhận tiền thưởng',
  share_your_referral_code:
    '1. Chia sẻ mã giới thiệu của bạn cho bạn bè để đăng ký tài khoản qua viber, mesenger, telegram, facebook, twitter hoặc instagram.',
  after_friend_register:
    '2. Sau khi bạn bè đăng ký với ZK99, cả người giới thiệu và thành viên được giới thiệu đều phải gửi tiền để nhận phần thưởng này.',
  refer_a_friend_bonus: '3. Tính thưởng giới thiệu bạn bè:',
  turnover_played: 'Doanh thu đã chơi x Hoa hồng % = Tiền thưởng giới thiệu bạn bè',
  example: 'Ví dụ:',
  turnover_formula: 'Doanh thu (1.000.000) x 0,002% (% hoa hồng) = 2.000MMK',
  note_bonus_only: '*Lưu ý: Tiền thưởng chỉ có thể nhận vào sau 12h trưa ngày hôm sau',
  note_bonus_only_x: '*Lưu ý: Tiền thưởng chỉ có thể nhận vào sau {{x}} ngày hôm sau',
  access_dashboard:
    '4. Truy cập bảng điều khiển để kiểm tra lợi nhuận được hưởng, bậc hoa hồng, lịch sử yêu cầu và tuyến dưới.',
  click_this_button_to_claim: 'Nhấp vào nút này để nhận Tiền thưởng giới thiệu bạn bè',
  commision_tier: 'Bậc hoa hồng',
  member_downline: 'Tuyến dưới của thành viên',
  bonus_tutorial_video: 'Video hướng dẫn thưởng',
  popularity: 'Phổ biến',
  no_balance: 'Bạn không có đủ số dư để tiếp tục',
  invalid_captcha: 'hình ảnh xác thực không hợp lệ',
  captcha: 'mã ngẫu nhiên',
  claim_bonus: 'Khẳng định',
  claimed: 'đã tuyên bố',
  bonus_claimed: 'yêu cầu tiền thưởng',
  online_banking: 'Ngân hàng trực tuyến',
  please_enter_email: 'Vui lòng nhập địa chỉ email của bạn để tiến hành quá trình đặt lại mật khẩu',
  please_select_a_bank: 'Vui lòng chọn ngân hàng',
  deposit_from_bank: 'Gửi tiền từ ngân hàng',
  from_selected_promotion: 'từ khuyến mãi đã chọn',
  from_selected_bank: 'từ ngân hàng được chọn',
  network_error: 'Lỗi mạng',
  we_have_sent_otp: 'Chúng tôi đã gửi OTP tới số điện thoại của bạn',
  your_otp_expire: 'OTP của bạn sẽ hết hạn sau',
  didnt_receive: 'Không nhận được tin nhắn?',
  send_again: 'Gửi lại',
  resend_again: 'Gửi lại sau',
  invalid_verification: 'Mã xác minh không hợp lệ!',
  otp_expired: 'OTP đã hết hạn, vui lòng nhập lại số điện thoại của bạn và thử lại!',
  otp_expired_redirect: 'OTP đã hết hạn! chuyển hướng để đăng nhập',
  captcha_check_failed: 'Kiểm tra hình ảnh xác thực không thành công!',
  verify_phonenumber: 'Xác minh số điện thoại',
  send_otp: 'Gửi OTP',
  success_verification:
    'Số điện thoại của bạn đã được xác minh! Vui lòng điền vào mẫu dưới đây để hoàn tất đăng ký của bạn.',
  dont_show_again: 'Không hiển thị lại',
  next: 'Tiếp',
  back: 'Quay lại',
  close: 'Đóng',
  payment_draft:
    'Nếu bạn xác nhận rằng bạn đã thanh toán, vui lòng đợi một lúc vì thanh toán có thể vẫn đang được xử lý',
  go_to_home: 'Đi về nhà',
  payout: `Thanh toán`,
  get: `lấy`,
  deposit_fee: `Phí đặt cọc`,
  total_amount_received: `Tổng số tiền nhận được`,
  cancel: `Hủy bỏ`,
  deposit_amount: `Số tiền gửi`,
  confirm_to_pay: `Xác nhận thanh toán`,
  deposit_method: `Phương thức gửi tiền`,
  redeem_angpau: `ĐỔI ANGPAU`,
  promo_concluded: `Khuyến mãi đã kết thúc`,
  can_only_claim: `Chỉ có thể nhận tiền thưởng từ {{x}} đến {{y}} Giờ Malaysia`,
  please_login: `Vui lòng hãy đăng nhập`,
  terms_n_conditions: `ĐIỀU KHOẢN VÀ ĐIỀU KIỆN`,
  claim_now: `YÊU CẦU NGAY BÂY GIỜ`,
  the_activity_x_in: `Hoạt động {{x}} trong`,
  starts: `Bắt đầu`,
  ends: `Kết thúc`,
  list_of_winners: `DANH SÁCH NGƯỜI CHIẾN THẮNG`,
  days: `Ngày`,
  hours: `Giờ`,
  minutes: `Phút`,
  seconds: `Giây`,
  please_select_payment_method: 'Vui lòng chọn phương thức thanh toán',
};

export default vn;
