// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const bn = {
  // login
  login: `বিঃদ্রঃ`,

  // register
  register: `নিবন্ধন`,

  // home
  game_partners: `খেলা অংশীদার`,
  responsible_gaming: `দায়িত্বশীল গেমিং`,
  supported_bank: `সমর্থিত ব্যাংক`,
  load_more: `আর ঢুকাও`,

  // game
  choose_your_favourite_x_games: `আপনার পছন্দের {{x}}টি গেম বেছে নিন`,
  choose_your_favourite_x: `Choose Your Favourite {{x}}`,
  launch: `শুরু করা`,

  // notification
  notifications: `বিজ্ঞপ্তি`,
  you_have_x_unread_messages: `আপনার {{x}}টি অপঠিত বার্তা আছে৷`,

  // deposit/withdraw
  drop_or_select_file: `ড্রপ বা সিলেক্ট ফাইল`,
  drop_files_here: `এখানে ফাইল ড্রপ করুন`,
  bank: `ব্যাংক`,
  bank_name: `ব্যাংকের নাম`,
  account_name: `হিসাবের নাম`,
  account_number: `হিসাব নাম্বার`,
  bank_account: `ব্যাংক হিসাব`,
  something_went_wrong: `কিছু ভুল হয়েছে. সমর্থন যোগাযোগ করুন.`,
  deposit_amount_between_x_and_y: `জমার পরিমাণ অবশ্যই {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `সর্বনিম্ন পরিমাণ হল {{x}}{{y}}`,
  payment_method: `মূল্যপরিশোধ পদ্ধতি`,
  mode: `মোড`,
  transfer_to: `স্থানান্তর করা`,
  select: `নির্বাচন করুন`,
  account: `হিসাব`,
  amount: `পরিমাণ`,
  deposit: `জমা`,
  withdraw: `প্রত্যাহার করুন`,
  amount_no_exceed_balance: `প্রত্যাহারের পরিমাণ উপলব্ধ ব্যালেন্সের বেশি হওয়া উচিত নয়`,
  minimum_withdraw_amount_is_x: `সর্বনিম্ন উত্তোলনের পরিমাণ হল {{x}}`,
  available_balance: `পর্যাপ্ত টাকা`,
  amount_withdrawn: `টাকা প্রত্যাহার করা হয়েছে`,
  no_bank_account: `প্রত্যাহার করার জন্য আপনার কোনো অ্যাকাউন্ট নেই, এখনই যোগ করুন`,
  add_account: `হিসাব যোগ করা`,
  account_name_must_be_4: `অ্যাকাউন্টের নাম দৈর্ঘ্যে 4-এর বেশি হতে হবে`,
  please_select_bank: `একটি ব্যাঙ্ক অ্যাকাউন্ট নির্বাচন করুন`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বর দৈর্ঘ্যে 10-14 হতে হবে`,
  minimum_length_is_x: `সর্বনিম্ন দৈর্ঘ্য হল {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য হল {{x}}`,
  withdraw_information: `তথ্য প্রত্যাহার`,
  account_holder_name: `হিসাব দাতার নাম`,
  name_of_the_account_holder: `অ্যাকাউন্টধারীর নাম`,
  fill_in_bank_account: `ব্যাঙ্ক অ্যাকাউন্ট পূরণ করুন`,
  please_upload_receipt: `অনুগ্রহ করে রসিদ আপলোড করুন`,
  deposit_bonus: `ডিপোজিট বোনাস`,
  bank_transfer: `ব্যাংক লেনদেন`,
  upload_receipt: `রসিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোন প্রচার নেই`,
  network_error: 'নেটওয়ার্ক ত্রুটি',
  we_have_sent_otp: 'আমরা আপনার ফোন নম্বরে OTP প্রেরণ করেছি',
  your_otp_expire: 'আপনার OTP মেয়াদ উত্তীর্ণ হবে',
  didnt_receive: 'মেসেজ পেলেন না?',
  send_again: 'আবার পাঠান',
  resend_again: 'আবার পাঠান মুলতুবি',
  invalid_verification: 'অবৈধ যাচাই কোড!',
  otp_expired: 'OTP মেয়াদ উত্তীর্ণ হয়েছে, দয়া করে আবার ফোন নম্বর প্রবেশ করুন এবং আবার চেষ্টা করুন!',
  otp_expired_redirect: 'OTP মেয়াদ উত্তীর্ণ হয়েছে! লগইনে পুনঃনির্দেশনা দেওয়া হচ্ছে',
  captcha_check_failed: 'ক্যাপচা চেক ব্যর্থ!',
  verify_phonenumber: 'ফোন নম্বর যাচাই করুন',
  send_otp: 'OTP পাঠান',
  success_verification: 'আপনার ফোন নম্বর যাচাই করা হয়েছে! আপনার নিবন্ধন শেষ করতে নিচের ফর্ম পূরণ করুন।',

  // transaction history
  transaction_history: `আপনি কি আমার সাথে কি করতে চান`,

  // bet history
  bet_history: `বাজি ইতিহাস`,
  game_provider: `গেম প্রদানকারী`,
  bet_count: `বাজি গণনা`,
  bet_amount: `বাজির পরিমাণ`,
  win_loss: `জয়/পরাজয়`,
  rebate: `রিবেট`,
  rebates: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `খেলা বিভাগ`,

  // all
  all: `সব`,
  x_is_required: `{{x}} প্রয়োজন৷`,
  note: `বিঃদ্রঃ`,
  save: `সংরক্ষণ`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `টাইপ`,
  status: `স্ট্যাটাস`,
  remark: `মন্তব্য`,
  clear: `পরিষ্কার`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ`,
  category: `শ্রেণী`,
  search: `অনুসন্ধান করুন`,
  new: `নতুন`,
  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  logout: `প্রস্থান`,
  username: `ব্যবহারকারীর নাম`,
  password: `পাসওয়ার্ড`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন`,
  confirm: `নিশ্চিত করুন`,
  dont_have_account: `একটি ব্যবহারকারী অ্যাকাউন্ট নেই?`,
  contact_us: `যোগাযোগ করুন`,
  game_menu: `গেম মেনু`,
  bonus: 'বোনাস',
  no_data: `কোন ডেটা নেই`,
  terms_and_condition: 'বিধি - নিষেধ এবং শর্তাবলী',
  game_list: 'খেলার তালিকা',
  play: 'খেলা',
  games: 'গেমস',
  casino: 'ক্যাসিনো',
  slots: 'স্লট',
  card: 'কার্ড',
  fishing: 'মাছ ধরা',
  sport: 'খেলা',
  sports: 'খেলা',
  bonuses: 'বোনাস',
  account_profile: `অ্যাকাউন্ট প্রোফাইল`,
  email: `ইমেইল`,
  full_name: `পুরো নাম`,
  phone_number: `ফোন নম্বর`,
  level: `স্তর`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `বোনাসের জন্য আপনার জন্মদিন লিখুন! 🎉🎁`,
  referral_code: `রেফারেল কোড`,
  invalid_x: `অবৈধ {{x}}`,
  extra: `অতিরিক্ত`,
  referral: `সুপারিশ`,
  my_referral: `আমার রেফারেল`,
  my_claim: `আমার দাবি`,
  claim_profit: `লাভ দাবি`,
  available_profit: `উপলব্ধ লাভ`,
  claimed_profit: `Claimed Profit`,
  all_time_profit: `সর্বকালের লাভ`,
  referral_note_on_x: `প্রতিবার আপনার বন্ধুরা {{x}} এ খেলে লাভ উপার্জন করুন`,
  tier: `স্তর`,
  commission: `কমিশন`,
  referred_member: `উল্লেখিত সদস্য`,
  profit_earned: `লাভ অর্জিত`,
  account_information: `হিসাবের তথ্য`,
  invite_friends: `বন্ধুদের আমন্ত্রণ জানান`,
  how_it_work: `এটি কিভাবে কাজ করে?`,
  invite_note: `আপনার বন্ধু আপনার রেফারেল কোড ব্যবহার করে সাইন আপ করলে একচেটিয়া রেফারেল বোনাস পান!`,
  share_via: `এর মাধ্যমে শেয়ার করুন`,
  referral_link: `আমার স্নাতকের`,
  total_profit: `সমস্ত লাভ`,
  copied: `কপি করা হয়েছে`,
  nothing_to_claim: `দাবি করার কিছুই নেই`,
  claim_history: `দাবি ইতিহাস`,
  from: `থেকে`,
  to: `প্রতি`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `তারিখ থেকে এবং থেকে উভয়ই নির্বাচন করুন`,
  hello: 'হ্যালো',
  my_downline: `আমার ডাউনলাইন`,
  downline: `ডাউনলাইন`,
  forgot_password_contact:
    'আপনার পাসওয়ার্ড পরিবর্তন করতে সাহায্য করার জন্য অনুগ্রহ করে নীচে দেওয়া গ্রাহক সহায়তার সাথে যোগাযোগ করুন৷',
  submit: 'জমা দিন',
  current_password: 'বর্তমান পাসওয়ার্ড',
  new_password: 'নতুন পাসওয়ার্ড',
  confirm_new_password: 'নিশ্চিত কর নতুন গোপননম্বর',
  already_have_an_account: 'ইতিমধ্যে একটি সদস্যপদ আছে?',
  share: 'শেয়ার করুন',
  more_info: 'অধিক তথ্য',
  copy_link: 'লিংক কপি করুন',
  share_to_friend: 'বন্ধুকে শেয়ার করুন',
  get_bonus: 'বোনাস পান',
  copy_your_referral:
    'আপনার রেফারেল লিঙ্ক কপি করুন এবং সোশ্যাল মিডিয়াতে পাঠান এবং আপনার ডাউনলাইন থেকে ক্রেডিট উপার্জন করুন!',
  card_game: 'কার্ড খেলা',
  e_sports: 'ই-স্পোর্টস',
  instant_game: 'তাত্ক্ষণিক খেলা',
  sort: 'সাজান',
  name: 'নাম',
  default: 'ডিফল্ট',
  e_sports_bonus: 'ই-স্পোর্টস বোনাস',
  fishing_bonus: 'মাছ ধরা বোনাস',
  casino_bonus: 'ক্যাসিনো বোনাস',
  sports_bonus: 'খেলা বোনাস',
  slots_bonus: 'স্লট বোনাস',
  instant_game_bonus: 'তাত্ক্ষণিক খেলা বোনাস',
  card_game_bonus: 'কার্ড খেলা বোনাস',
  all_bonus: 'সব বোনাস',
  how_to_get_referral_bonus: 'রেফারাল বোনাস পাওয়ার উপায়',
  steps_to_claim: 'বোনাস দাবির পদক্ষেপ',
  share_your_referral_code:
    '1. আপনার রেফারাল কোডটি আপনার বন্ধুদের সাথে ভাগ করুন, ভাইবার, মেসেঞ্জার, টেলিগ্রাম, ফেসবুক, টুইটার বা ইনস্টাগ্রামের মাধ্যমে অ্যাকাউন্ট রেজিস্টার করতে।',
  after_friend_register:
    '2. বন্ধু রেজিস্টার করার পর, ZK99 এর সাথে নিজেকে এবং রেফারাল সদস্যকে এই বোনাস দাবি করতে অবশ্যই ডিপোজিট করতে হবে।',
  refer_a_friend_bonus: '3. রেফার বনাস হিসাব:',
  turnover_played: 'খেলা টার্নওভার x কমিশন % = রেফারাল বনাস',
  example: 'উদাহরণ:',
  turnover_formula_x: 'টার্নওভার (1,000,000) x 0.002% (কমিশন %) = 2,000{{x}}',
  note_bonus_only: '* নোট: বোনাসটি শুধুমাত্র রাত 12:00 টা পর প্রাপ্ত করা যাবে',
  note_bonus_only_x: '*নোট: বোনাসটি শুধুমাত্র পরবর্তী দিনে {{x}} পরে দাবি করা যাবে',
  access_dashboard: '4. লাভযোগ্য লাভ, কমিশন টিয়ার, দাবি ইতিহাস এবং ডাউনলাইন চেক করতে ড্যাশবোর্ড অ্যাক্সেস করুন।',
  click_this_button_to_claim: 'রেফারাল বনাস দাবি করতে এই বাটনটি ক্লিক করুন',
  commision_tier: 'কমিশন টিয়ার',
  member_downline: 'সদস্য ডাউনলাইন',
  bonus_tutorial_video: 'বোনাস টিউটোরিয়াল ভিডিও',
  popularity: 'জনপ্রিয়তা',
  invalid_captcha: 'অবৈধ ক্যাপচা',
  captcha: 'ক্যাপচা',
  claim_bonus: 'বোনাস দাবি করুন',
  claimed: 'দাবি করেছে',
  bonus_claimed: 'বোনাস দাবি করা হয়েছে',
  online_banking: 'অনলাইন ব্যাংকিং',
  please_enter_email: 'পাসওয়ার্ড রিসেট প্রক্রিয়ার সাথে এগিয়ে যেতে আপনার ইমেল ঠিকানা লিখুন',
  please_select_a_bank: 'একটি ব্যাঙ্ক নির্বাচন করুন',
  deposit_from_bank: 'ব্যাংক থেকে জমা',
  from_selected_promotion: 'নির্বাচিত প্রচার থেকে',
  from_selected_bank: 'নির্বাচিত ব্যাংক থেকে',
  dont_show_again: 'আবার দেখিও না',
  next: 'পরবর্তী',
  back: 'পেছনে',
  close: 'বন্ধ',
  payment_draft:
    'আপনি যদি নিশ্চিত হন যে আপনি ইতিমধ্যেই অর্থপ্রদান করেছেন, অনুগ্রহ করে কিছুক্ষণ অপেক্ষা করুন কারণ অর্থপ্রদান এখনও প্রক্রিয়াধীন হতে পারে',
  go_to_home: 'বাড়িতে যেতে',
  payout: `পেআউট`,
  get: 'নিন',
  deposit_fee: 'জমা ফি',
  total_amount_received: 'মোট প্রাপ্ত পরিমাণ',
  cancel: 'বাতিল করুন',
  deposit_amount: 'জমার পরিমাণ',
  confirm_to_pay: 'পেমেন্ট নিশ্চিত করুন',
  deposit_method: 'জমা পদ্ধতি',
  redeem_angpau: 'অ্যাংপাউ রিডিম করুন',
  promo_concluded: 'প্রচারণা সমাপ্ত',
  can_only_claim: 'শুধুমাত্র {{x}} থেকে {{y}} মালয়েশিয়া সময়ের মধ্যে বোনাস দাবি করতে পারেন',
  please_login: 'দয়া করে লগইন করুন',
  terms_n_conditions: 'শর্তাবলী',
  claim_now: 'এখন দাবি করুন',
  the_activity_x_in: 'ক্রিয়াকলাপ {{x}} এ',
  starts: 'শুরু',
  ends: 'শেষ',
  list_of_winners: 'বিজয়ীদের তালিকা',
  days: 'দিন',
  hours: 'ঘন্টা',
  minutes: 'মিনিট',
  seconds: 'সেকেন্ড',
  please_select_payment_method: 'একটি পেমেন্ট পদ্ধতি নির্বাচন করুন',
};

export default bn;
